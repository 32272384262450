import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import InlineHelp from "../../../../../components/inline-help"
import NotationGroup from "../../../../../components/notation-group"
import Note from "../../../../../components/note"
import PoemMelodieNotation from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/poem-melodie-notation"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/melodie-des-gedichts/loesung" />
      }
      navigation={navigation}
    >
      <Seo title="Das Gedicht und seine Melodie" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Das Gedicht und seine Melodie
          </Heading>
          <Paragraph>
            Hölderlin nannte sein Gedicht ein »kunstlos Lied« – und tatsächlich
            hat er die Zeilen wie ein Musikstück komponiert. Wie er das wohl
            gemacht hat? Bestimme die Anzahl der Silben in jedem Wort. Das geht
            am besten, wenn du die Wörter laut vorsprichst. Du kannst die Silben
            mit den Fingern zählen oder auf einem Blatt Papier eine Strichliste
            anlegen.
          </Paragraph>

          <TaskSidebar
            main={
              <NotationGroup
                onNotationsUpdated={(notations) => {
                  setAnswer({
                    courseId: "hoelderlins-reisen",
                    chapterId: "02-reiseerinnerung",
                    taskId: "melodie-des-gedichts",
                    answer: notations,
                  })
                }}
              >
                <PoemMelodieNotation />
              </NotationGroup>
            }
            sidebar={
              <Stack space={6}>
                <Note variant="task">
                  Bestimme die Anzahl der Silben in jedem Wort. Klicke auf ein
                  Wort, um die passende Silbenzahl auszuwählen.
                </Note>
                <InlineHelp title="Wie erkenne ich eine Silbe?">
                  Manche Wörter bestehen nur aus einer Silbe (z.B. Eis oder
                  Tee). Manche Wörter bestehen aber auch aus mehreren Silben
                  (z.B. Ku-chen oder Mar-me-la-de). Du erkennst sie daran, dass
                  du zwischen den Silben beim Sprechen eine Pause machen kannst.
                  Bei den meisten Wörtern beginnt eine neue Silbe mit einem
                  Konsonanten.
                </InlineHelp>
              </Stack>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
