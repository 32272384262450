import React, { useState, useRef } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { useThemeUI } from "theme-ui"
import { RoughNotation } from "react-rough-notation"
import Box from "./box"
import { useNotationGroup } from "./notation-group"
import Tippy from "@tippyjs/react/headless"
import Inline from "./inline"

const doc = typeof document !== "undefined" && document

export const selectables = [
  {
    id: "s1",
    color: "spring",
    content: "1",
  },
  {
    id: "s2",
    color: "summer",
    content: "2",
  },
  {
    id: "s3",
    color: "autumn",
    content: "3",
  },
  {
    id: "s4",
    color: "winter",
    content: "4",
  },
  {
    id: "s5",
    color: "negative",
    content: "5",
  },
]

const NotationSelection = (props) => {
  const { children, id, type } = props
  const { state, setNotationChanged } = useNotationGroup()
  const [selectionVisible, setSelectionVisible] = useState(false)
  const context = useThemeUI()
  const { theme } = context
  // see https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
  let focusTimeout = null

  const show = state?.[id]?.active
  const currentSelected = selectables.find(
    (selectable) => selectable.id === state?.[id]?.selection
  )
  const currentColor = currentSelected
    ? theme.colors[currentSelected.color]
    : undefined

  const onClickSelectable = ({ selectable }) => {
    setNotationChanged({
      id,
      active: true,
      selection: selectable.id,
    })
    hideSelection()
  }

  const showSelection = () => {
    setSelectionVisible(true)
  }

  const hideSelection = () => {
    setSelectionVisible(false)
  }

  return (
    <Box
      as="span"
      onBlur={() => {
        focusTimeout = setTimeout(() => {
          hideSelection()
        })
      }}
      onFocus={() => {
        clearTimeout(focusTimeout)
      }}
    >
      <Tippy
        interactive={true}
        appendTo={doc.body}
        visible={selectionVisible}
        onClickOutside={hideSelection}
        render={(attrs) => (
          <Box
            sx={{
              bg: "background",
              borderColor: "contrast",
              borderRadius: "lg",
              borderStyle: "solid",
              borderWidth: 2,
              p: 3,
            }}
            tabIndex="-1"
            {...attrs}
          >
            <Inline space={0}>
              {selectables.map((selectable) => (
                <Box
                  as="button"
                  cursor="pointer"
                  key={selectable.id}
                  sx={{
                    appearance: "none",
                    borderRadius: "lg",
                    width: 10,
                    height: 10,
                    color:
                      currentSelected === selectable
                        ? "white"
                        : selectable.color,
                    fontSize: 4,
                    fontFamily: "sans",
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bg:
                      currentSelected === selectable
                        ? currentColor
                        : "transparent",
                    "&:hover, &:focus": {
                      bg: "muted",
                    },
                  }}
                  onClick={() => {
                    onClickSelectable({ selectable })
                  }}
                >
                  {selectable.content}
                </Box>
              ))}
            </Inline>
          </Box>
        )}
      >
        <Box
          as="button"
          cursor="pointer"
          onClick={showSelection}
          sx={{ appearance: "none", bg: "transparent" }}
        >
          <RoughNotation show={show} type={type} color={currentColor}>
            {children}
          </RoughNotation>
        </Box>
      </Tippy>
    </Box>
  )
}

NotationSelection.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  selectables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
}

NotationSelection.defaultProps = {
  type: "highlight",
  color: "negative",
}

export default NotationSelection
