import React, { useState } from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Text from "./text"
import TextLink from "./textlink"

const InlineHelp = ({ children, title, ...restProps }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Box>
      {expanded ? (
        <Text>{children}</Text>
      ) : (
        <TextLink
          onClick={(event) => {
            event.preventDefault()
            setExpanded(true)
          }}
        >
          {title}
        </TextLink>
      )}
    </Box>
  )
}

InlineHelp.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default InlineHelp
